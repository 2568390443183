<template>
  <v-row>
    <v-col cols="12" class="pb-0"><h3 class="text-center font-weight-medium">Medios de pago disponibles</h3></v-col>
    <v-col cols="12" v-if="loading">
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader
              type="list-item-avatar"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
              type="list-item-avatar"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
              type="list-item-avatar"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="py-0"
           :class="{'mb-3': tipo_pago == null || tipo_pago == ID_TIPO_PAGO.REDCOMPRA || tipo_pago == ID_TIPO_PAGO.TGR}"
           v-if="!loading">
      <v-row>
        <v-col cols="12" class="pt-3" v-if="precio_total_carro == 0 || has_por_cotizar">
          <v-alert
              text
              type="info"
              outlined
              class="mb-0"
          >
            <p class="mb-0 text-justify">
              Su carro de compras posee ítems por pagar y otros por cotizar. Se generará su cotizacion por un monto total sujeto a carillaje. El precio final de
              su
              solicitud será informado vía correo en unos días y podrá retomar el pago desde la sección "Mis
              Compras".
            </p>

          </v-alert>
        </v-col>

        <v-col v-if="precio_total_carro > 0" cols="12" class="py-0">
          <v-radio-group
              v-model="tipo_pago"
              row
              class="mt-0 pt-0"
              hide-details
          >
            <v-row>
              <!--              <v-col cols="12"  sm="6"-->
              <!--                     v-if="!hideItem(ID_TIPO_PAGO.REDCOMPRA)"-->
              <!--                     class="d-flex align-center mt-3 justify-center"-->
              <!--                     @click="tipo_pago = ID_TIPO_PAGO.REDCOMPRA">-->
              <!--                <div class="d-flex align-center justify-center">-->
              <!--                  <v-radio-->
              <!--                      class="mr-0"-->
              <!--                      label=""-->
              <!--                      :value="ID_TIPO_PAGO.REDCOMPRA"-->
              <!--                  >-->
              <!--                    <template v-slot:label>-->
              <!--                      <img style="width: 100px " :src="require('@/assets/img/modal-pago/logo_redcompra.png')"/>-->
              <!--                    </template>-->

              <!--                  </v-radio>-->

              <!--                </div>-->
              <!--              </v-col>-->
              <v-col
                  v-if="!hideItem(ID_TIPO_PAGO.TGR)"
                  class="d-flex align-sm-center mt-3 justify-sm-center pb-3 ml-7 ml-sm-0"
                  @click="tipo_pago = ID_TIPO_PAGO.TGR">
                <div class="d-flex align-center justify-center">
                  <v-radio
                      class="mr-0"
                      label=""
                      :value="ID_TIPO_PAGO.TGR"
                  >
                    <template v-slot:label>
                      <img style="height: 28px " :src="require('@/assets/img/modal-pago/logo_tgr.svg')"/>
                    </template>
                  </v-radio>

                </div>

              </v-col>

              <v-col
                  v-if="!hideItem(ID_TIPO_PAGO.KHIPU_WEB)"
                  class="d-flex align-sm-center mt-3 justify-sm-center ml-7 ml-sm-0"
                  @click="tipo_pago = ID_TIPO_PAGO.KHIPU_WEB">
                <div class="d-flex align-center justify-center">
                  <v-radio
                      class="mr-0"
                      label=""
                      :value="ID_TIPO_PAGO.KHIPU_WEB"
                  >
                    <template v-slot:label>
                      <img style="height: 32px " :src="require('@/assets/img/modal-pago/logo_khipu.svg')"/>
                    </template>

                  </v-radio>

                </div>

              </v-col>
              <v-col
                  v-if="!hideItem(ID_TIPO_PAGO.KHIPU)"
                  class="d-flex align-sm-center mt-3 justify-sm-center pb-3 ml-7 ml-sm-0"
                  @click="tipo_pago = ID_TIPO_PAGO.KHIPU">
                <div class="d-flex align-center justify-center">
                  <v-radio
                      class="mr-0"
                      label=""
                      :value="ID_TIPO_PAGO.KHIPU"
                  >
                    <template v-slot:label>
                      <img style="height:33px " :src="require('@/assets/img/modal-pago/logo_cta_empresa.svg')"/>
                    </template>
                  </v-radio>

                </div>

              </v-col>
            </v-row>
          </v-radio-group>

        </v-col>

      </v-row>
    </v-col>
    <v-fade-transition>
      <v-col cols="12" v-if="tipo_pago != null && tipo_pago != ID_TIPO_PAGO.REDCOMPRA"
             class=" pb-3 pt-3">
        <p class="text-center mb-0" style="font-size: 12px" v-if="tipo_pago === ID_TIPO_PAGO.TGR">Utilice tarjetas de Débito, Crédito y casas comerciales a través del botón de pago de la Tesorería de la República.</p>

        <p class="text-center mb-0" style="font-size: 12px" v-if="tipo_pago === ID_TIPO_PAGO.KHIPU_WEB">
          Realice un pago <b>simplificado</b>, Khipu otorga una conexión segura con su banco desde el
          navegador para concretar su compra.
        </p>
        <p class="text-center mb-0" style="font-size: 12px" v-if="tipo_pago === ID_TIPO_PAGO.KHIPU">
          Realice una transferencia <b>manual</b> desde Cuentas de Empresas utilizando el recaudador Khipu.
        </p>
      </v-col>
    </v-fade-transition>
  </v-row>
</template>

<script>
import {ID_TIPO_PAGO} from "@/model/carro/carro.entity";

export default {
  name: "ModalPagoMediosPago",
  props: {
    ayuda: {default: false},
    ocultarOpciones: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      default: false
    },

    precio_total_carro: {default: -1},
    has_por_cotizar: {default: false}
  },
  data() {
    return {
      tipos_de_pago:[ID_TIPO_PAGO.KHIPU,ID_TIPO_PAGO.KHIPU_WEB,ID_TIPO_PAGO.TGR],
      tipo_pago: null,
      ID_TIPO_PAGO: ID_TIPO_PAGO,
      verAyuda: false,
      tipos_pagos_disponibles: ID_TIPO_PAGO.DISPONIBLES
    }
  },
  created() {
    this.verAyuda = this.ayuda
    if (this.precio_total_carro == 0) {
      this.tipo_pago = ID_TIPO_PAGO.POR_COTIZAR;
      console.log("intenta setear el tipo pago en por cotizar: ", this.tipo_pago)

      this.$emit("updateTipoPago", this.tipo_pago)
    }

  },
  mounted(){
    if(this.ocultarOpciones.length==2){
      console.log(this.tipo_pago)
      this.ocultarOpciones.forEach(opciones =>{
        this.tipos_de_pago.map((tipo_pago,index) =>{
          if(opciones.id_tipo_pago == tipo_pago){
           this.tipos_de_pago[index] = null
          }else{
            console.log("NO hay coincidencia", opciones.id_tipo_pago)
          }
        })
      })

      this.tipos_de_pago.forEach(tipo_pago=> {
        if(tipo_pago != null){
          this.tipo_pago = tipo_pago
        }
      })
    }
  },
  watch: {
    tipo_pago() {
      this.$emit("updateTipoPago", this.tipo_pago)
    },
    precio_total_carro() {

      if (this.precio_total_carro == 0) {
        this.tipo_pago = ID_TIPO_PAGO.POR_COTIZAR;
        this.$emit("updateTipoPago", this.tipo_pago)
      }
    },
  },
  methods: {
    hideItem(tipo_pago) {
      let item = this.ocultarOpciones.find(f => {
        return f.id_tipo_pago == tipo_pago;
      })
      return item != null
    }
  }
}
</script>

<style scoped>

</style>
