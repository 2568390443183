<template>
    <v-row >
      <v-col cols="12" class="px-15 pt-5" v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.POR_COTIZAR">
        <p class="text-center">
          Estamos generando su solicitud, por favor, espere un momento...
        </p>
        <v-progress-linear
            color="purple lighten-2"
            buffer-value="0"
            reverse
            stream
        ></v-progress-linear>
      </v-col>

      <v-col cols="12" class="px-15" v-if="tipo_pago_seleccionado != ID_TIPO_PAGO.POR_COTIZAR">
        <div class="text-center pt-2">
          <img v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.KHIPU || tipo_pago_seleccionado == ID_TIPO_PAGO.KHIPU_WEB" src="@/assets/img/khipu.png"
               width="200"/>
          <img v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.REDCOMPRA"
               src="@/assets/img/redcompra.png" width="200"/>
          <img v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.TGR"
               src="@/assets/img/modal-pago/logo_tgr.svg" width="200"/>
        </div>
        <p class="text-center pb-3">Conectando con
          <span v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.KHIPU_WEB || tipo_pago_seleccionado == ID_TIPO_PAGO.KHIPU_WEB">Khipu</span>
          <span v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.REDCOMPRA">Redcompra</span>
          <span v-if="tipo_pago_seleccionado == ID_TIPO_PAGO.TGR">Tesorería General de la Repúlica</span>, espere un
          momento...</p>
        <v-progress-linear
            color="purple lighten-2"
            buffer-value="0"
            reverse
            stream
        ></v-progress-linear>
      </v-col>


    </v-row>
</template>

<script>
import {ID_TIPO_PAGO} from "@/model/carro/carro.entity";

export default {
  name: "ModalPagoPantallaCarga",
  props: {
    tipo_pago_seleccionado: {default: null}
  },
  data(){
    return {
      ID_TIPO_PAGO: ID_TIPO_PAGO
    }
  }
}
</script>

<style scoped>

</style>
